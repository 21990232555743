body,
html {
  background-color: rgb(226, 226, 226);
  margin: 0;
  padding: 0;

  --spacing: 0.2rem;
  --font-size: 14px;
  font-size: 14px;
}

.canBeDisabled {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

#interface {
  display: contents;
}

#root {
  background-color: #fff;
}

.controls {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 200;
}

.types {
  z-index: 200;
  position: fixed;
  top: 85px;
  right: 0;
  height: calc(100vh - 160px);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  overflow-y: auto;
  padding-right: 5px;
  padding-left: 15px;

  transform: translateX(calc(100% - 40px));
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  &:hover {
    transform: translateX(0);
  }

  .divider {
    width: 100%;
    height: 21px;
    border-bottom: 1px solid #9f9f9f;
    color: black;
    margin: 5px 0;
    flex-shrink: 0;
  }

  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar
  {
    width: 4px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    background-color: #0000006e;
    border: 2px solid #55555586;
  }
}

.type {
  padding: 2px;
  margin: 0;
  display: block;
  height: 24px;
  width: 88px;
  border: 2px solid rgba(0, 0, 0, 0.201);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  flex-shrink: 0;

  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateX(-3px);
  }

  &--selected, &--selected:hover {
    border: 2px solid #000;
    transform: translateX(-10px);
  }

  color: black;
  &--void, 
  &--fuel, 
  &--charcoal, 
  &--cooltool, 
  &--heattool,
  &--hot,
  &--cold,
  &--grounding {
    color: white;
  }
}

.meta {
  position: fixed;
  top: 50px;
  left: 3px;
  pointer-events: none;
  z-index: 200;
}

.queueSize {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 200;

  height: 5px;
  width: 0px;
  background-color: #ffc107;
  transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &.big {
    background-color: #f44336;
  }
  &.small {
    background-color: #4caf50;
  }
}

canvas {
  border: 1px solid transparent;
  cursor: none;
  transition: border-color 0.2s ease;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  &.debug,
  &.thermovision {
    z-index: 100;
    pointer-events: none;
    opacity: 0.55;
  }

  &.thermovision {
    opacity: 0.5;
    filter: blur(10px);
  }
}

.cursor {
  position: fixed;
  border-radius: 100%;
  border: 2px solid currentColor;
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  pointer-events: none;
  will-change: transform;
  background-color: #0000000a;
  z-index: 200;

  &_meta {
    font-family: Arial;

    position: absolute;
    white-space: nowrap;

    background-color: rgba(0, 0, 0, 0.5);
    color: white;

    padding: 2px 4px;
    border-radius: 2px;

    margin-left: 150px;
    margin-bottom: 30px;

    .arrow-container {
      height: 30px;
      width: 30px;
      position: relative;

      .arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 2px;
        background: #fff;
        transform-origin: center center;

        &::after {
          content: '';
          height: 4px;
          width: 4px;
          background: red;
          position: absolute;
          right: 0;
          top: -1px;
        }
      }
    }
  }

  &--active {
    visibility: visible;
  }

  &::after {
    content: '';
    background-color: currentColor;
    width: 5px;
    height: 5px;
    border-radius: 100%;
  }
}

#github {
  z-index: 200;
  position: fixed;
  top: 0;
  right: 0;
}

.inline {
  #github {
    display: none;
  }
  .types {
    top: 10px;
    height: calc(100vh - 100px);
  }
}